import React from "react"
import * as Icon from "react-feather"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Document Data Extraction And Analysis"
const pageTitle = "Document Data Extraction And Analysis"
const subTitle = "Data Science"
const para ="Documents may be significantly degraded, including physical document damage, poor scanning, and poor image quality Formats can vary significantly, and may contain complex structures which can confuse off-the-shelf software"
const blockquote = `"ATYETI BUILDS SCALABLE COMPLEX
LEARNING SYSTEMS FOR LARGE SCALE
DOCUMENT EXTRACTIONS. HIGH ACCURACY
IS ACHIEVABLE DESPITE DEGRADATION,
HANDWRITING, AND VARIABLE FORMATS."`

const cardItems = [
  {
    id: 1,
    heading: `Clients and Industries:`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Credit Suisse
        </li>
        <li>
          <Icon.Square />
          Patent River
        </li> 
        <li>
          <Icon.Square />
          Anaqua
        </li>
        <li>
          <Icon.Square />
          KYC Automation, Prosecution Analytics, Prosecution Simulation,
          Language Modeling, Identity Resolution{" "}
        </li>
      </>
    ),
  },
  {
    id: 2,
    heading: `Business Problem`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Scanned documents contain information locked in an image and require a
          recognition and analysis processes in order to extract that
          information reliably for business use.{" "}
        </li>
        <li>
          <Icon.Square />
          Legal documents hand-signed and then scanned
        </li>
        <li>
          <Icon.Square />
          Faxes
        </li>
        <li>
          <Icon.Square />
          Hand-filled forms
        </li>
        <li>
          <Icon.Square />
          Physical mail{" "}
        </li>
      </>
    ),
  },
  {
    id: 3,
    heading: `Recognition`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Custom-tuned deep learning models designed specifically for the
          subject
        </li>
        <li>
          <Icon.Square />
          Scalable, on-demand parallel processes for large volumes
        </li>
        <li>
          <Icon.Square />
          Custom entity definitions, static and variable formats, and
          accommodation for complex or unknown page layouts{" "}
        </li>
      </>
    ),
  },
  {
    id: 4,
    heading: `Analysis`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Contextual analysis helps refine and validate extraction{" "}
        </li>
        <li>
          <Icon.Square />
          Mixing tools like ML, RegEx, and expert rule systems allows multiple
          levels at which data errors can be corrected or raised{" "}
        </li>
        <li>
          <Icon.Square />
          Targeted pri orities, information type frequencies, and error tracking
          allow analysts to tune the process and achieve high levels of accuracy{" "}
        </li>
      </>
    ),
  },
  {
    id: 4,
    heading: `Result : Large Scale, Accurate Results`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Atyeti systems have extracted data from over 2 billion documents{" "}
        </li>
        <li>
          <Icon.Square />
          Deep NLP systems perform multiple tasks including complex entity
          recognition, conversational mapping, and data verification
        </li>
        <li>
          <Icon.Square />
          Our computer vision systems provide handwriting recognition, figure
          extraction, feature identification, figureto-text mapping and document
          de-degradation{" "}
        </li>
      </>
    ),
  },
]
const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query DocumentDataExtractionAndAnalysissQuery {
    casedetailsJson(slug: { eq: "document-data-extraction-and-analysis" }) {
      
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails